@import "/src/assets/style/variables";
.selectScorecard {
  min-height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px dashed rgba($black, 0.1);
  margin: 2px 0 25px 0;
  border-radius: 6px;
  .selectScorecardSelect {
    display: grid;
    button {
      margin: 0 auto 8px;
    }
  }
}
