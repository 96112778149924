// border width
.border-w-1 {
  border-width: 1px;
}
.border-w-2 {
  border-width: 2px;
}
.border-w-3 {
  border-width: 3px;
}
.border-w-4 {
  border-width: 4px;
}
.border-w-5 {
  border-width: 5px;
}
.border-w-6 {
  border-width: 6px;
}
.border-w-7 {
  border-width: 7px;
}
.border-w-8 {
  border-width: 8px;
}
.border-w-9 {
  border-width: 9px;
}
.border-w-10 {
  border-width: 10px;
}
// border color black
.border-black-1 {
  border-color: rgba($black, 0.05);
}
.border-black-2 {
  border-color: rgba($black, 0.1);
}
.border-black-3 {
  border-color: rgba($black, 0.15);
}
.border-black-4 {
  border-color: rgba($black, 0.2);
}
.border-black-5 {
  border-color: rgba($black, 0.25);
}
.border-black-6 {
  border-color: rgba($black, 0.3);
}
.border-black-7 {
  border-color: rgba($black, 0.35);
}
.border-black-8 {
  border-color: rgba($black, 0.4);
}
.border-black-9 {
  border-color: rgba($black, 0.45);
}
.border-black-10 {
  border-color: rgba($black, 0.5);
}
.border-black-11 {
  border-color: rgba($black, 0.55);
}
.border-black-12 {
  border-color: rgba($black, 0.6);
}
.border-black-13 {
  border-color: rgba($black, 0.65);
}
.border-black-14 {
  border-color: rgba($black, 0.7);
}
.border-black-15 {
  border-color: rgba($black, 0.75);
}
.border-black-16 {
  border-color: rgba($black, 0.8);
}
.border-black-17 {
  border-color: rgba($black, 0.9);
}
.border-black-full {
  border-color: rgba($black, 1);
}
// .border-primary {
//   border-color: $primary;
// }
.border-primary {
  border-color: var(--color-main) !important;
}
// border color white
.border-white-1 {
  border-color: rgba($white, 0.05);
}
.border-white-2 {
  border-color: rgba($white, 0.1);
}
.border-white-3 {
  border-color: rgba($white, 0.15);
}
.border-white-4 {
  border-color: rgba($white, 0.2);
}
.border-white-5 {
  border-color: rgba($white, 0.25);
}
.border-white-6 {
  border-color: rgba($white, 0.3);
}
.border-white-7 {
  border-color: rgba($white, 0.35);
}
.border-white-8 {
  border-color: rgba($white, 0.4);
}
.border-white-9 {
  border-color: rgba($white, 0.45);
}
.border-white-10 {
  border-color: rgba($white, 0.5);
}
.border-white-11 {
  border-color: rgba($white, 0.55);
}
.border-white-12 {
  border-color: rgba($white, 0.6);
}
.border-white-13 {
  border-color: rgba($white, 0.65);
}
.border-white-14 {
  border-color: rgba($white, 0.7);
}
.border-white-15 {
  border-color: rgba($white, 0.75);
}
.border-white-16 {
  border-color: rgba($white, 0.8);
}
.border-white-17 {
  border-color: rgba($white, 0.9);
}
.border-white-full {
  border-color: rgba($white, 1);
}
// border style
.border-solid {
  border-style: solid;
}
.border-dashed {
  border-style: dashed;
}
.border-dotted {
  border-style: dotted;
}
.border-double {
  border-style: double;
}
.border-outset {
  border-style: outset;
}

// border width top
.border-top-w-1 {
  border-top-width: 1px;
}
.border-top-w-2 {
  border-top-width: 2px;
}
.border-top-w-3 {
  border-top-width: 3px;
}
.border-top-w-4 {
  border-top-width: 4px;
}
.border-top-w-5 {
  border-top-width: 5px;
}
.border-top-w-6 {
  border-top-width: 6px;
}
.border-top-w-7 {
  border-top-width: 7px;
}
.border-top-w-8 {
  border-top-width: 8px;
}
.border-top-w-9 {
  border-top-width: 9px;
}
.border-top-w-10 {
  border-top-width: 10px;
}
// border color black top
.border-top-1 {
  border-top-color: rgba($black, 0.05);
}
.border-top-2 {
  border-top-color: rgba($black, 0.1);
}
.border-top-3 {
  border-top-color: rgba($black, 0.15);
}
.border-top-4 {
  border-top-color: rgba($black, 0.2);
}
.border-top-5 {
  border-top-color: rgba($black, 0.25);
}
.border-top-6 {
  border-top-color: rgba($black, 0.3);
}
.border-top-7 {
  border-top-color: rgba($black, 0.35);
}
.border-top-8 {
  border-top-color: rgba($black, 0.4);
}
.border-top-9 {
  border-top-color: rgba($black, 0.45);
}
.border-top-10 {
  border-top-color: rgba($black, 0.5);
}
.border-top-11 {
  border-top-color: rgba($black, 0.55);
}
.border-top-12 {
  border-top-color: rgba($black, 0.6);
}
.border-top-13 {
  border-top-color: rgba($black, 0.65);
}
.border-top-14 {
  border-top-color: rgba($black, 0.7);
}
.border-top-15 {
  border-top-color: rgba($black, 0.75);
}
.border-top-16 {
  border-top-color: rgba($black, 0.8);
}
.border-top-17 {
  border-top-color: rgba($black, 0.9);
}
.border-top-full {
  border-top-color: rgba($black, 1);
}
.border-top-primary {
  border-top-color: var(--color-main) !important;
}
// border color white top
.border-top-white-1 {
  border-top-color: rgba($white, 0.05);
}
.border-top-white-2 {
  border-top-color: rgba($white, 0.1);
}
.border-top-white-3 {
  border-top-color: rgba($white, 0.15);
}
.border-top-white-4 {
  border-top-color: rgba($white, 0.2);
}
.border-top-white-5 {
  border-top-color: rgba($white, 0.25);
}
.border-top-white-6 {
  border-top-color: rgba($white, 0.3);
}
.border-top-white-7 {
  border-top-color: rgba($white, 0.35);
}
.border-top-white-8 {
  border-top-color: rgba($white, 0.4);
}
.border-top-white-9 {
  border-top-color: rgba($white, 0.45);
}
.border-top-white-10 {
  border-top-color: rgba($white, 0.5);
}
.border-top-white-11 {
  border-top-color: rgba($white, 0.55);
}
.border-top-white-12 {
  border-top-color: rgba($white, 0.6);
}
.border-top-white-13 {
  border-top-color: rgba($white, 0.65);
}
.border-top-white-14 {
  border-top-color: rgba($white, 0.7);
}
.border-top-white-15 {
  border-top-color: rgba($white, 0.75);
}
.border-top-white-16 {
  border-top-color: rgba($white, 0.8);
}
.border-top-white-17 {
  border-top-color: rgba($white, 0.9);
}
.border-top-white-full {
  border-top-color: rgba($white, 1);
}
// border style top
.border-top-solid {
  border-top-style: solid;
}
.border-top-dashed {
  border-top-style: dashed;
}
.border-top-dotted {
  border-top-style: dotted;
}
.border-top-double {
  border-top-style: double;
}
.border-top-outset {
  border-top-style: outset;
}

// border width right
.border-right-w-1 {
  border-right-width: 1px;
}
.border-right-w-2 {
  border-right-width: 2px;
}
.border-right-w-3 {
  border-right-width: 3px;
}
.border-right-w-4 {
  border-right-width: 4px;
}
.border-right-w-5 {
  border-right-width: 5px;
}
.border-right-w-6 {
  border-right-width: 6px;
}
.border-right-w-7 {
  border-right-width: 7px;
}
.border-right-w-8 {
  border-right-width: 8px;
}
.border-right-w-9 {
  border-right-width: 9px;
}
.border-right-w-10 {
  border-right-width: 10px;
}
// border color black right
.border-right-1 {
  border-right-color: rgba($black, 0.05);
}
.border-right-2 {
  border-right-color: rgba($black, 0.1);
}
.border-right-3 {
  border-right-color: rgba($black, 0.15);
}
.border-right-4 {
  border-right-color: rgba($black, 0.2);
}
.border-right-5 {
  border-right-color: rgba($black, 0.25);
}
.border-right-6 {
  border-right-color: rgba($black, 0.3);
}
.border-right-7 {
  border-right-color: rgba($black, 0.35);
}
.border-right-8 {
  border-right-color: rgba($black, 0.4);
}
.border-right-9 {
  border-right-color: rgba($black, 0.45);
}
.border-right-10 {
  border-right-color: rgba($black, 0.5);
}
.border-right-11 {
  border-right-color: rgba($black, 0.55);
}
.border-right-12 {
  border-right-color: rgba($black, 0.6);
}
.border-right-13 {
  border-right-color: rgba($black, 0.65);
}
.border-right-14 {
  border-right-color: rgba($black, 0.7);
}
.border-right-15 {
  border-right-color: rgba($black, 0.75);
}
.border-right-16 {
  border-right-color: rgba($black, 0.8);
}
.border-right-17 {
  border-right-color: rgba($black, 0.9);
}
.border-right-full {
  border-right-color: rgba($black, 1);
}
.border-right-primary {
  border-right-color: var(--color-main) !important;
}
// border color white right
.border-right-white-1 {
  border-right-color: rgba($white, 0.05);
}
.border-right-white-2 {
  border-right-color: rgba($white, 0.1);
}
.border-right-white-3 {
  border-right-color: rgba($white, 0.15);
}
.border-right-white-4 {
  border-right-color: rgba($white, 0.2);
}
.border-right-white-5 {
  border-right-color: rgba($white, 0.25);
}
.border-right-white-6 {
  border-right-color: rgba($white, 0.3);
}
.border-right-white-7 {
  border-right-color: rgba($white, 0.35);
}
.border-right-white-8 {
  border-right-color: rgba($white, 0.4);
}
.border-right-white-9 {
  border-right-color: rgba($white, 0.45);
}
.border-right-white-10 {
  border-right-color: rgba($white, 0.5);
}
.border-right-white-11 {
  border-right-color: rgba($white, 0.55);
}
.border-right-white-12 {
  border-right-color: rgba($white, 0.6);
}
.border-right-white-13 {
  border-right-color: rgba($white, 0.65);
}
.border-right-white-14 {
  border-right-color: rgba($white, 0.7);
}
.border-right-white-15 {
  border-right-color: rgba($white, 0.75);
}
.border-right-white-16 {
  border-right-color: rgba($white, 0.8);
}
.border-right-white-17 {
  border-right-color: rgba($white, 0.9);
}
.border-right-white-full {
  border-right-color: rgba($white, 1);
}
// border style right
.border-right-solid {
  border-right-style: solid;
}
.border-right-dashed {
  border-right-style: dashed;
}
.border-right-dotted {
  border-right-style: dotted;
}
.border-right-double {
  border-right-style: double;
}
.border-right-outset {
  border-right-style: outset;
}

// border width bottom
.border-bottom-w-1 {
  border-bottom-width: 1px;
}
.border-bottom-w-2 {
  border-bottom-width: 2px;
}
.border-bottom-w-3 {
  border-bottom-width: 3px;
}
.border-bottom-w-4 {
  border-bottom-width: 4px;
}
.border-bottom-w-5 {
  border-bottom-width: 5px;
}
.border-bottom-w-6 {
  border-bottom-width: 6px;
}
.border-bottom-w-7 {
  border-bottom-width: 7px;
}
.border-bottom-w-8 {
  border-bottom-width: 8px;
}
.border-bottom-w-9 {
  border-bottom-width: 9px;
}
.border-bottom-w-10 {
  border-bottom-width: 10px;
}
// border color black bottom
.border-bottom-1 {
  border-bottom-color: rgba($black, 0.05);
}
.border-bottom-2 {
  border-bottom-color: rgba($black, 0.1);
}
.border-bottom-3 {
  border-bottom-color: rgba($black, 0.15);
}
.border-bottom-4 {
  border-bottom-color: rgba($black, 0.2);
}
.border-bottom-5 {
  border-bottom-color: rgba($black, 0.25);
}
.border-bottom-6 {
  border-bottom-color: rgba($black, 0.3);
}
.border-bottom-7 {
  border-bottom-color: rgba($black, 0.35);
}
.border-bottom-8 {
  border-bottom-color: rgba($black, 0.4);
}
.border-bottom-9 {
  border-bottom-color: rgba($black, 0.45);
}
.border-bottom-10 {
  border-bottom-color: rgba($black, 0.5);
}
.border-bottom-11 {
  border-bottom-color: rgba($black, 0.55);
}
.border-bottom-12 {
  border-bottom-color: rgba($black, 0.6);
}
.border-bottom-13 {
  border-bottom-color: rgba($black, 0.65);
}
.border-bottom-14 {
  border-bottom-color: rgba($black, 0.7);
}
.border-bottom-15 {
  border-bottom-color: rgba($black, 0.75);
}
.border-bottom-15 {
  border-bottom-color: rgba($black, 0.8);
}
.border-bottom-15 {
  border-bottom-color: rgba($black, 0.9);
}
.border-bottom-full {
  border-bottom-color: rgba($black, 1);
}
.border-bottom-primary {
  border-bottom-color: var(--color-main) !important;
}
// border color white bottom
.border-bottom-white-1 {
  border-bottom-color: rgba($white, 0.05);
}
.border-bottom-white-2 {
  border-bottom-color: rgba($white, 0.1);
}
.border-bottom-white-3 {
  border-bottom-color: rgba($white, 0.15);
}
.border-bottom-white-4 {
  border-bottom-color: rgba($white, 0.2);
}
.border-bottom-white-5 {
  border-bottom-color: rgba($white, 0.25);
}
.border-bottom-white-6 {
  border-bottom-color: rgba($white, 0.3);
}
.border-bottom-white-7 {
  border-bottom-color: rgba($white, 0.35);
}
.border-bottom-white-8 {
  border-bottom-color: rgba($white, 0.4);
}
.border-bottom-white-9 {
  border-bottom-color: rgba($white, 0.45);
}
.border-bottom-white-10 {
  border-bottom-color: rgba($white, 0.5);
}
.border-bottom-white-11 {
  border-bottom-color: rgba($white, 0.55);
}
.border-bottom-white-12 {
  border-bottom-color: rgba($white, 0.6);
}
.border-bottom-white-13 {
  border-bottom-color: rgba($white, 0.65);
}
.border-bottom-white-14 {
  border-bottom-color: rgba($white, 0.7);
}
.border-bottom-white-15 {
  border-bottom-color: rgba($white, 0.75);
}
.border-bottom-white-16 {
  border-bottom-color: rgba($white, 0.8);
}
.border-bottom-white-17 {
  border-bottom-color: rgba($white, 0.9);
}
.border-bottom-white-full {
  border-bottom-color: rgba($white, 1);
}
// border style bottom
.border-bottom-solid {
  border-bottom-style: solid;
}
.border-bottom-dashed {
  border-bottom-style: dashed;
}
.border-bottom-dotted {
  border-bottom-style: dotted;
}
.border-bottom-double {
  border-bottom-style: double;
}
.border-bottom-outset {
  border-bottom-style: outset;
}

// border width left
.border-left-w-1 {
  border-left-width: 1px;
}
.border-left-w-2 {
  border-left-width: 2px;
}
.border-left-w-3 {
  border-left-width: 3px;
}
.border-left-w-4 {
  border-left-width: 4px;
}
.border-left-w-5 {
  border-left-width: 5px;
}
.border-left-w-6 {
  border-left-width: 6px;
}
.border-left-w-7 {
  border-left-width: 7px;
}
.border-left-w-8 {
  border-left-width: 8px;
}
.border-left-w-9 {
  border-left-width: 9px;
}
.border-left-w-10 {
  border-left-width: 10px;
}
// border color black left
.border-left-1 {
  border-left-color: rgba($black, 0.05);
}
.border-left-2 {
  border-left-color: rgba($black, 0.1);
}
.border-left-3 {
  border-left-color: rgba($black, 0.15);
}
.border-left-4 {
  border-left-color: rgba($black, 0.2);
}
.border-left-5 {
  border-left-color: rgba($black, 0.25);
}
.border-left-6 {
  border-left-color: rgba($black, 0.3);
}
.border-left-7 {
  border-left-color: rgba($black, 0.35);
}
.border-left-8 {
  border-left-color: rgba($black, 0.4);
}
.border-left-9 {
  border-left-color: rgba($black, 0.45);
}
.border-left-10 {
  border-left-color: rgba($black, 0.5);
}
.border-left-11 {
  border-left-color: rgba($black, 0.55);
}
.border-left-12 {
  border-left-color: rgba($black, 0.6);
}
.border-left-13 {
  border-left-color: rgba($black, 0.65);
}
.border-left-14 {
  border-left-color: rgba($black, 0.7);
}
.border-left-15 {
  border-left-color: rgba($black, 0.75);
}
.border-left-16 {
  border-left-color: rgba($black, 0.8);
}
.border-left-17 {
  border-left-color: rgba($black, 0.9);
}
.border-left-full {
  border-left-color: rgba($black, 1);
}
.border-left-primary {
  border-left-color: var(--color-main) !important;
}
// border color white left
.border-left-white-1 {
  border-left-color: rgba($white, 0.05);
}
.border-left-white-2 {
  border-left-color: rgba($white, 0.1);
}
.border-left-white-3 {
  border-left-color: rgba($white, 0.15);
}
.border-left-white-4 {
  border-left-color: rgba($white, 0.2);
}
.border-left-white-5 {
  border-left-color: rgba($white, 0.25);
}
.border-left-white-6 {
  border-left-color: rgba($white, 0.3);
}
.border-left-white-7 {
  border-left-color: rgba($white, 0.35);
}
.border-left-white-8 {
  border-left-color: rgba($white, 0.4);
}
.border-left-white-9 {
  border-left-color: rgba($white, 0.45);
}
.border-left-white-10 {
  border-left-color: rgba($white, 0.5);
}
.border-left-white-11 {
  border-left-color: rgba($white, 0.55);
}
.border-left-white-12 {
  border-left-color: rgba($white, 0.6);
}
.border-left-white-13 {
  border-left-color: rgba($white, 0.65);
}
.border-left-white-14 {
  border-left-color: rgba($white, 0.7);
}
.border-left-white-15 {
  border-left-color: rgba($white, 0.75);
}
.border-left-white-16 {
  border-left-color: rgba($white, 0.8);
}
.border-left-white-17 {
  border-left-color: rgba($white, 0.9);
}
.border-left-white-full {
  border-left-color: rgba($white, 1);
}
// border style left
.border-left-solid {
  border-left-style: solid;
}
.border-left-dashed {
  border-left-style: dashed;
}
.border-left-dotted {
  border-left-style: dotted;
}
.border-left-double {
  border-left-style: double;
}
.border-left-outset {
  border-left-style: outset;
}

.border-radius-1 {
  border-radius: 1px;
}
.border-radius-2 {
  border-radius: 2px;
}
.border-radius-3 {
  border-radius: 3px;
}
.border-radius-4 {
  border-radius: 4px;
}
.border-radius-5 {
  border-radius: 5px;
}
