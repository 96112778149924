// microsoft
// .bg-microsoft {
//   background: $bg-microsoft !important;
// }

// bg color black
.bg-black-1 {
  background: $black-1 !important;
}
.bg-black-2 {
  background: $black-2 !important;
}
.bg-black-3 {
  background: $black-3 !important;
}
.bg-black-4 {
  background: $black-4 !important;
}
.bg-black-5 {
  background: $black-5 !important;
}
.bg-black-6 {
  background: $black-6 !important;
}
.bg-black-7 {
  background: $black-7 !important;
}
.bg-black-8 {
  background: $black-8 !important;
}
.bg-black-9 {
  background: $black-9 !important;
}
.bg-black-10 {
  background: $black-10 !important;
}
.bg-black-11 {
  background: $black-11 !important;
}
.bg-black-12 {
  background: $black-12 !important;
}
.bg-black-13 {
  background: $black-13 !important;
}
.bg-black-14 {
  background: $black-14 !important;
}
// text color black
.text-black-1 {
  color: $black-1 !important;
}
.text-black-2 {
  color: $black-2 !important;
}
.text-black-3 {
  color: $black-3 !important;
}
.text-black-4 {
  color: $black-4 !important;
}
.text-black-5 {
  color: $black-5 !important;
}
.text-black-6 {
  color: $black-6 !important;
}
.text-black-7 {
  color: $black-7 !important;
}
.text-black-8 {
  color: $black-8 !important;
}
.text-black-9 {
  color: $black-9 !important;
}
.text-black-10 {
  color: $black-10 !important;
}
.text-black-11 {
  color: $black-11 !important;
}
.text-black-12 {
  color: $black-12 !important;
}
.text-black-13 {
  color: $black-13 !important;
}
.text-black-14 {
  color: $black-14 !important;
}

// bg color white
// .bg-white-1 {
//   background: $white-1 !important;
// }
// .bg-white-2 {
//   background: $white-2 !important;
// }
// .bg-white-3 {
//   background: $white-3 !important;
// }
// .bg-white-4 {
//   background: $white-4 !important;
// }
// .bg-white-5 {
//   background: $white-5 !important;
// }
// .bg-white-6 {
//   background: $white-6 !important;
// }
// .bg-white-7 {
//   background: $white-7 !important;
// }
// .bg-white-8 {
//   background: $white-8 !important;
// }
// .bg-white-9 {
//   background: $white-9 !important;
// }
// .bg-white-10 {
//   background: $white-10 !important;
// }
// .bg-white-11 {
//   background: $white-11 !important;
// }
// .bg-white-12 {
//   background: $white-12 !important;
// }
// .bg-white-13 {
//   background: $white-13 !important;
// }
// .bg-white-14 {
//   background: $white-14 !important;
// }
// text color white
// .text-white-1 {
//   color: $white-1 !important;
// }
// .text-white-2 {
//   color: $white-2 !important;
// }
// .text-white-3 {
//   color: $white-3 !important;
// }
// .text-white-4 {
//   color: $white-4 !important;
// }
.text-white-5 {
  color: $white-5 !important;
}
// .text-white-6 {
//   color: $white-6 !important;
// }
// .text-white-7 {
//   color: $white-7 !important;
// }
// .text-white-8 {
//   color: $white-8 !important;
// }
// .text-white-9 {
//   color: $white-9 !important;
// }
// .text-white-10 {
//   color: $white-10 !important;
// }
// .text-white-11 {
//   color: $white-11 !important;
// }
.text-white-12 {
  color: $white-12 !important;
}
// .text-white-13 {
//   color: $white-13 !important;
// }
// .text-white-14 {
//   color: $white-14 !important;
// }

// custom
.bg-purpleBlue {
  background: #888ac1;
}
.bg-skyBlue {
  background: #50a0e4;
}
// .bg-purpleLite {
//   background: #c48dcb;
// }
// .bg-greenBlue {
//   background: #52b3bf;
// }
// .bg-greenDarkDull {
//   background: #7bbc8d;
// }
// .bg-blueDarkDull {
//   background: #7bb4bc;
// }
// .bg-greenLightDull {
//   background: #7dbf7e;
// }
// .bg-redLightDull {
//   background: #d87a7a;
// }
// .bg-redYellowDull {
//   background: #d89c7a;
// }
// .bg-purpleDull {
//   background: #d494b7;
// }
// .bg-blueRoyalDull {
//   background: #7b97bc;
// }
// .bg-yellowDull {
//   background: #eee793;
// }
.bg-greenDarkDLL {
  background: #5ead4c;
}
.bg-greenDLL {
  background: #c7eac0;
}
.bg-blueDLL {
  background: #c4dce1;
}
// .bg-orangeDLL {
//   background: #f0dba3;
// }
.bg-yellowDLL {
  background: #ebe281;
}
// .bg-orangeLight {
//   color: #ff8d42;
// }
.bg-purpleLight {
  background: #e178b2;
}
// .bg-yellowLight {
//   background: #ffc107;
// }

// textColor
// .text-purpleBlue {
//   color: #888ac1;
// }
// .text-skyBlue {
//   color: #50a0e4;
// }
// .text-purpleLite {
//   color: #c48dcb;
// }
// .text-greenBlue {
//   color: #52b3bf;
// }
// .text-greenDarkDull {
//   color: #7bbc8d;
// }
// .text-blueDarkDull {
//   color: #7bb4bc;
// }
// .text-greenLightDull {
//   color: #7dbf7e;
// }
// .text-greenLightOps {
//   color: #7bcb7c;
// }
// .text-redLightDull {
//   color: #d87a7a;
// }
// .text-redLight {
//   color: #e84646;
// }
// .text-redLightFill {
//   color: #de5b5b;
// }
// .text-redYellowDull {
//   color: #d89c7a;
// }
// .text-purpleDull {
//   color: #d494b7;
// }
// .text-blueRoyalDull {
//   color: #7b97bc;
// }
// .text-yellowDull {
//   color: #dcce6e;
// }
// .text-yellowLight {
//   color: #f1cd51;
// }
// .text-orangeLight {
//   color: #ff8d42;
// }
