.call-actions {
    // > .card {
    //     border-radius: 0;
    //     border-top: 0;
    //     &:first-child {
    //         border-top-left-radius: 0.25rem;
    //         border-top-right-radius: 0.25rem;
    //         border-width: 1px;
    //     }
    //     &:last-child {
    //         margin-bottom: 3px;
    //         border-bottom-left-radius: 0.25rem;
    //         border-bottom-right-radius: 0.25rem;
    //     }
    // }
}
.status-dropdown {
    display: flex;
    .k-dropdownlist {
        border: 0;
        //background-color: transparent;
        align-items: center;
        justify-content: space-between;

        padding: 0 5px;
        border-radius: 5px !important;
        &::after {
            display: none;
        }
        span {
            color: #fff !important;
        }
        .k-button {
            &, &:hover, &:focus, &::before, &:hover::before, &:focus::before {
                background-color: transparent;
            }
        }
        &:has(span.text-secondary) {
            background-color: #6c757d;
        }
        &:has(span.text-warning) {
            background-color: #ffc107;
        }
        &:has(span.text-success) {
            background-color: #28a745;
        }
        &:has(span.text-danger) {
            background-color: #dc3545;
        }
    }
}
.k-popup {
    .k-list-content {
        .k-list-ul {
            li {
                padding: 3px;
                cursor: pointer;
                &:hover {
                    background-color: rgba(0,0,0, 0.05);
                }
            }
        }
    }
}