.form-group {
    margin: 0;
    .form-control {
        min-height: 30px;
        height: 34px;
    }
}
.k-input:not(:-webkit-autofill) {
    animation-name: autoFillEnd;
}
.k-input-solid {
    border-color: rgba($black, 0.08);
    color: #424242;
    background-color: $white;
}
.k-input,
.k-picker {
    margin: 0;
    padding: 0;
    width: 100%;
    min-width: 0;
    box-sizing: border-box;
    border-width: 1px;
    border-style: solid;
    outline: 0;
    font-family: inherit;
    font-size: 14px;
    line-height: 1.4285714286;
    font-weight: normal;
    text-align: start;
    box-shadow: none;
    display: inline-flex;
    flex-flow: row nowrap;
    align-items: stretch;
    vertical-align: middle;
    position: relative;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-appearance: none;
}
// Login start
.formGroup {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    z-index: 3;
    position: relative;
    .k-form {
        min-width: 440px;
    }
}
.formInrG {
    background: $white;
    padding: 15px 30px 30px 30px;
    border-radius: 0.25rem;
}
.k-floating-label-container > .k-label,
.k-floating-label-container.k-state-focused > .k-label,
.k-floating-label-container.k-focus > .k-label {
    top: 4px;
    transform: scale(1);
}
.k-floating-label-container > .k-label {
    max-width: 90%;
    font-size: 0.875rem;
}
.formInrG .hdrTrk-logo img {
    height: 20px;
    width: auto;
}
.k-form .k-form-legend {
    margin-bottom: 0px;
    border-bottom: none;
}
.or-divider {
    font-size: 15px;
    font-weight: 600;
    line-height: normal;
    color: #333;
    text-transform: uppercase;
    width: 100%;
    text-align: center;
    span {
        display: inline-block;
        position: relative;
        padding: 0;
        font-weight: 500;
        &::before,
        &::after {
            content: "";
            position: absolute;
            top: 50%;
            width: 140px;
            opacity: 0.5;
            border-top: solid 1px #333;
        }
        &::before {
            right: 100%;
            margin-right: 15px;
        }
        &::after {
            left: 100%;
            margin-left: 15px;
        }
    }
}
.loginBg {
    background: url("../images/login-bg.jpg");
    z-index: 2;
    background-attachment: fixed;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -webkit-transition: background-image 300ms ease-in 200ms;
    transition: background-image 300ms ease-in 200ms;
    &:after {
        background: rgba($white, 0.9);
        height: 100%;
        width: 100%;
        float: left;
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        z-index: 1;
    }
}
.formLabel label {
    font-size: 14px;
}
.imgBtn .k-button-text {
    display: flex;
    align-items: center;
}
// Login end
.h-autoTextArea textarea {
    height: auto;
}
.editableInput {
    background: rgba($white, 0.7);
    border: 1px solid rgba($black, 0.2) !important;
}
.multiselectArrowIcon .k-selection-multiple {
    width: calc(100% - 25px - 32px);
}

.iBtnDisable .k-button-solid-base:disabled,
.k-button-solid-base.k-disabled,
.k-custom-tab .k-tabstrip > .k-tabstrip-items-wrapper > .k-tabstrip-items .k-item.k-disabled,
.k-disabled,
*:disabled {
    cursor: not-allowed !important;
    opacity: 0.5 !important;
    filter: grayscale(100%) !important;
}
