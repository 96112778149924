.errorContainer {
  position: fixed;
  background: $white;
  height: calc(100% - 55px - 38px);
  top: 55px;
}
.errorImg img {
  background-size: cover;
}
// @media (min-width: 767px) {
//   .errorContainer {
//     position: fixed;
//   }
// }
