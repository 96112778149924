// Dashboard CSS
.dash-container {
    height: auto;
    overflow: hidden;
    position: relative;
    min-height: 600px;
    & .text-11 {
        font-size: 32px;
        font-weight: 400;
        line-height: 1.7;
    }
    & .text-4 {
        font-size: 18px;
        font-weight: 400;
        line-height: 1.7;
    }
}
.home-user {
    float: left;
    position: relative;
    width: 100%;
    padding: 0 60px 0 60px;
    // @include border-radius (4px);
}
.detail-trk {
    float: left;
    width: 100%;
    padding: 70px 0 0 0;
    .trk-title {
        font-size: 24px;
        padding: 0 0 50px 0;
        font-weight: 600;
    }
}
.trk-container {
    float: left;
    width: 100%;
    .trk-inr {
        float: left;
        width: 100%;
        padding: 0 0 50px 0;
        .trk-h {
            float: left;
            width: 100%;
            font-size: 18px;
            border-bottom: 1px solid rgba($black,.15);
            padding: 0 0 7px 0;
            margin: 0 0 15px 0;
        }
        .trk-row {
            float: left;
            width: 100%;
            position: relative;
            .trkColH {
                font-size: 20px;
                font-weight: 600;
            }
            .trkCol {
                display: flex;
                padding: 3px 0 3px 0;
                font-size: 14px;
                .trkCol-h {
                    color: #222;
                    font-weight: 600;
                    min-width: 300px;
                    padding: 3px 3px 3px 3px;
                }
                .trkCol-dot {
                    padding: 3px 6px;
                    font-size: 15px;
                }
                .trkCol-p {
                    color: #333;
                    padding: 3px 3px 3px 3px;
                    border: 2px solid $white;
                    position: relative;
                    &:focus {
                        border: 2px solid #aaa;
                        outline: none;
                        border-radius: 5px;
                    }
                    
                }
            }
        }
    }
}
.trkcollink-a {
    font-size: 14px;
    margin: 0 30px 10px 0;
    text-decoration: underline;
    float: left;
    color: #6264a7;
}

.moreOption {
    display: block;
    position: absolute;
    right: -151px;
    top: 0;
    background: $white;
    box-shadow: 0 5px 10px 0 rgba($black, .2);
    min-width: 120px;
    @include border-radius (4px);
    .moreOptionLi-a {
        border-top: 1px solid rgba($black,.1);
        float: left;
        width: 100%;
        padding: 5px 10px;
        &:hover {
            text-decoration: none;
        }
    }
}
.trkCol-p.moreTrkp i {
    font-size: 21px;
    position: absolute;
    top: 4px;
    margin: 0 0 0 5px;
    cursor: pointer;
    color: #7b7dbb;
    display: none;
}
.trkCol-p.moreTrkp.show i {
    font-size: 21px;
    position: absolute;
    top: 4px;
    margin: 0 0 0 5px;
    cursor: pointer;
    color: #7b7dbb;
    display: block;
    right: -24px;
}
.trkCol.showDrop .moreOption {
    display: block !important;
}
.smTxtLight {
    font-size: 12px;
    float: left;
    width: 100%;
    line-height: 15px;
    color: #999;
}


// dashFull start
.header-contain {
    background: $white;
    padding: 7px 30px 7px 30px;
    border-bottom: 1px solid rgba($black,.05);
}
.dsh-customer {
    float: left;
    width: 100%;
    .dshCust-list {
        float: left;
        width: 100%;
        height: calc(100% - 130px);
    }
    .dshTile {
        float: left;
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding: 0 0 25px 0;
        position: relative;
        .dshTile-trk {
            background: $white;
            color: $white;
            float: left;
            width: 100%;
            margin: 0 0 0 15px;
            padding: 10px;
            text-align: center;
            box-shadow: 0 1px 6px 0 rgba($black, .10);
            height: 105px;
            .dshTile-p {
                font-size: 14px;
                justify-content: center;
                height: 42px;
            }
            .dshTile-val {
                font-size: 26px;
            }
        }
        .dshTile-trk:first-child {
            margin: 0;
        }
    }
}

// dashFull end 


// dshNew
.uCard {
    float: left;
    width: 100%;
}

.tileRow {
    float: left;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.tileCol:first-child {
    margin: 0;
}

.tileCol {
    float: left;
    width: 100%;
    margin: 0 0 0 15px;
    background: $white;
    padding: 10px;
    border-radius: 5px;
    position: relative;
}

.tileColInr {
    display: flex;
    align-items: center;
    min-height: 75px;
}

.tileCol-i {
    padding: 0 15px 0 10px;
    font-size: 32px;
    opacity: .7;
}

.tileCol-filter {
    position: absolute;
    top: 6px;
    right: 10px;
    font-size: 16px;
    opacity: .7;
    transition: all 200ms linear;
}

    .tileCol-filter:hover {
        opacity: 1;
    }

.tileColTx-val {
    font-size: 26px;
}

.tileColTx-p {
    font-size: 14px;
}

.uCardRow {
    float: left;
    width: 100%;
    border-bottom: 1px solid rgba($black, 0.06);
    padding: 9px 20px 8px 20px;
}

    .uCardRow:last-child {
        border: none;
    }

.w-50 {
    width: 50%;
}

.progressUsr .progress {
    border-radius: 100px;
    height: 12px;
}
.mapTrk {
    width: 100%;
    height: 190px;
    float: left;
}
// dshNew



@media(max-width:1367px) {
    .detail-trk {
        padding: 40px 0 0 0;
    }
    .trk-container .trk-inr:last-child {
        padding: 0 0 20px 0;
    }
    .trk-container .trk-inr {
        padding: 0 0 30px 0;
    }
    .trk-container .trk-inr .trk-row .trkCol .trkCol-p {
        padding: 1px 3px 1px 3px;
    }
}
@media(max-width:1330px) {
    .trk-container .trk-inr .trk-row .trkCol .trkCol-h {
        min-width: 180px;
        max-width: 180px;
    }
    .trkCol-p {
        word-break: break-all;
    }
    .trkCol-dot {
        display: none;
    }
}
@media(max-width:991px) {
    .nav .nav-item .droplist-scrollbar {
        left: 15px;
        right: 15px;
    }
}
@media(max-width:850px) {
    .tileRow {
        display: block;
    }
    .tileRow .tileCol {
        margin: 0 0 10px 0;
    }
}

@media only screen and (max-width: 1200px) and (min-width: 767px)  {
    .trk-container .trk-inr .trk-row .trkCol {
        display: block;
        padding: 0 0 20px 0;
    }
    .smTxtLight {
        padding: 0 0 20px 0;
    }
}
@media(max-width:768px) {
    .dshCust-list {
        padding: 0 0 30px 0;
    }
}

@media(max-width:460px) {
    .trk-container .trk-inr .trk-row .trkCol .trkCol-h {
        min-width: 90px;
        max-width: 90px;
    }
    .trk-container .trk-inr .trk-row .trkCol .trkCol-a .trkcollink-a {
        width: 300px;
    }
    .trk-container .trk-inr .trk-row .trkCol {
        display: block;
        padding: 0 0 20px 0;
    }
    .smTxtLight {
        padding: 0 0 20px 0;
    }
    .trk-container .trk-inr .trk-row .trkCol .trkCol-h {
        max-width: 100%;
    }
}