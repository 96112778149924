.loaderBgOut {
  background: rgba($black, 0.6);
  width: 100%;
  height: 100vh;
  position: absolute;
  z-index: 1000;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loaderBgIn {
  float: left;
  text-align: center;
}
.loaderTxt {
  float: left;
  width: 100%;
  color: $white;
  padding: 20px 0 0 0;
  font-size: 18px;
}
