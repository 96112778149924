button {
  height: auto;
}
.k-button {
  text-transform: initial;
  font-weight: 500;
  font-size: 0.875rem;
  flex: none;
}
.k-button-md {
  padding: 5px 10px;
}
.hov-transparent .k-button:hover::before,
.k-button.k-hover::before ,
.hov-transparent .k-button:focus::before,
.k-button.k-focus::before {
  opacity: 0;
}
.k-button, button {
  &:hover, &:focus:not(:focus-visible) {
    outline: 0;
    box-shadow: none;
  }
}
.k-button-text {
  display: flex;
  align-items: center;
  justify-content: center;
}
// switch button
.switchButton .k-switch-md {
  width: 36px;
  height: 22px;
}
.k-switch-md .k-switch-track {
  width: 36px;
  height: 22px;
}
.k-switch-on {
  .k-switch-track {
    //background-color: rgba($primary, 1);
    background-color: var(--color-main);
  }
  .k-switch-thumb {
    background-color: $white;
  }
}
.k-switch-off {
  .k-switch-track {
    background-color: rgba($black, 0.17);
  }
} 
.k-switch .k-switch-thumb {
  box-shadow: none;
}
.k-switch-md .k-switch-thumb {
  width: 14px;
  height: 14px;
}
.k-switch-md.k-switch-on .k-switch-thumb-wrap {
  left: calc(100% - 10px - 1px);
}
.k-switch-md.k-switch-off .k-switch-thumb-wrap {
  left: calc(10px + 1px);
}
.k-switch.k-switch-on .k-switch-thumb::before {
  background-color: transparent;
}
.k-switch.k-switch-off .k-switch-thumb::before {
  background-color: transparent;
}
.rotate235 {
  transform: rotate(235deg);
}
.k-button-solid-primary {
  background-color: var(--color-main) !important;
  border-color: var(--color-main) !important;
}
.btn-link {
  color: var(--color-main);
  &:hover {
    color: var(--color-main) !important;
  }
}
.btn-primary {
  background-color: var(--color-main) !important;
  border-color: var(--color-main) !important;
}
.iconBtn {
  border: none;
  background: transparent;
  &:hover::before, &::before {
    opacity: 0;
  }
}
.disabledIconBtn {
  color: rgba($black, 0.2) !important;
  background: transparent !important;
  border: none;
}
.disabledBtn {
  color: rgba($black, 0.2) !important;
  background: rgba($black, 0.1) !important;
}
.btnGroupData .k-button.k-selected::before {
  height: 31px;
  opacity: 0.5;
  background: var(--color-main);
}
.btnGroupData .k-button.k-selected {
  color: $white;
}
.btnGroupData .k-button:hover {
  height: 31px;
}
.btnGroupData .k-button {
  height: 31px;
  font-family: "Poppins" !important;
  font-size: 13px;
  color: #333;
}
.btnGroupData .k-button-text {
  z-index: 9;
}
.keysButtons .keywordTrk {
  padding: 3px 5px 3px 5px;
}
.keysBtnGroup {
  display: flex;
}
.keysBtnGroup .keysBtn {
  padding: 0;
  height: 22px;
  width: 22px;
  border-radius: 50px;
  margin: 0 0 0 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba($black, 0.3);
  border: none;
  color: rgba($white, 0.6);
}
.keysButtons .mx-td-spn {
  max-height: 30px;
}