.k-stepper {
    .k-step-current,
    .k-step-done {
        &,
        &:hover {
            .k-step-indicator {
                border-color: var(--color-main);
                background-color: var(--color-main);
            }
        }
    }
}
.k-progressbar {
    background-color: #dadada;
    .k-selected {
        background-color: var(--color-main);
    }
}
