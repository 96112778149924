.call-block {
  float: left;
  width: 100%;
  display: flex;
  .chat-conversation {
    float: left;
    width: calc(100% - 440px);
  }
  .eval-cmnt {
    width: 420px;
    background: #fbfbfb;
    border-left: 1px solid #dfdfdf;
    margin: 0 0 0 20px;
  }
}
.chatBox {
  float: left;
  .list-inline,
  .list-unstyled {
    padding-left: 0;
    list-style: none;
    li {
      clear: both;
      .conversation-list {
        display: flex;
        position: relative;
        align-items: flex-start;
        width: 100%;
        .chat-avatar {
          margin: 0 16px 0 0;
          img {
            width: 36px;
            height: 36px;
            border-radius: 50%;
          }
        }
        .user-chat-content {
          float: left;
          width: 90%;
          .ctextBox {
            width: 100%;
            display: flex;
            justify-content: space-between;
            .ctext-wrap-i {
              float: left;
              a {
                padding: 2px;
                margin: 0 3px 2px 3px;
                float: left;
                display: flex;
                align-items: center;
                height: 24px;
                width: 24px;
              }
              svg {
                height: 18px;
                width: 18px;
                fill: var(--color-main);
              }
            }
            .ctext-wrap-h {
              font-weight: 600;
              font-size: 16px;
              padding: 0 0 5px 0;
            }
          }
          .ctext-wrap {
            display: block;
            .ctext-wrap-content {
              padding: 10px 15px;
              background-color: rgba(#c4c1ff, 0.2);
              border-radius: 8px 8px 8px 8px;
              color: #343a40;
              position: relative;
              animation: flyIn 0.6s ease-in-out;
              &:before {
                content: "";
                position: absolute;
                border-color: rgba(#c4c1ff, 0.2) transparent transparent rgba(#c4c1ff, 0.2);
                border-style: solid;
                border-width: 6px;
                left: -12px;
                right: auto;
                top: 17px;
                transform: rotate(180deg);
              }
              .ctext-wrap-content p {
                font-size: 14px;
              }
              .chat-time {
                font-size: 12px;
                margin-top: 4px;
                text-align: right;
                opacity: 0.6;
                svg {
                  height: 13px;
                  width: 13px;
                  fill: #333;
                }
              }
            }
          }
          .conversation-name {
            font-weight: 600;
            font-size: 14px;
          }
        }
      }
    }
    li.right {
      .conversation-list {
        float: right;
        text-align: right;
        justify-content: flex-end;
        .chat-avatar {
          order: 3;
          margin-right: 0;
          margin-left: 16px;
        }
        .user-chat-content {
          .ctext-wrap {
            justify-content: flex-end;
            .ctext-wrap-content {
              order: 2;
              background-color: rgba(#eaf1ff, 0.3);
              color: #343a40;
              text-align: right;
              border-radius: 8px 8px 0 8px;
              &:before {
                border-color: rgba(#eaf1ff, 0.3) rgba(#eaf1ff, 0.3) transparent transparent;
                border-style: solid;
                border-width: 5px;
                left: auto;
                right: 0;
              }
              .chat-time {
                text-align: left;
                color: #7a7f9a;
              }
            }
          }
        }
      }
    }
  }
}
.chat-usr-icon .btn:not(:disabled):not(.disabled) {
  background: transparent;
  border: none;
  color: transparent;
}
.editChat.chatEdited {
  border: 2px solid rgba($black, 0.3);
  outline: none;
  padding: 5px 4px;
  border-radius: 4px;
  min-height: 50px;
}
