.dialogSide .k-dialog {
  position: fixed;
  right: 0;
  top: 0;
  border-radius: 0;
  height: 100%;
  margin: 0;
  width: 438px;
  .k-dialog-titlebar {
    color: $white;
    background-color: var(--color-main);
    padding: 5px 15px;
    font-size: 1.09375rem;
    border-radius: 0;
  }
}
.dialogSide .k-window-content.k-dialog-content {
  padding: 0;
}
// chips groupBtn
.filterTrak-btn .k-button {
  background: rgba($black, 0.1);
  color: rgba($black, 0.66);
  margin: 0 4px 5px 0;
}
.filterTrak-btn .k-button-solid-base.k-selected {
  color: rgba($white, 0.87);
  //background: rgba($primary,.9);
  background: var(--color-main);
  opacity: 0.9;
}
.filterTrak-btn .k-button-group {
  flex-wrap: wrap;
}
.k-input {
  border: 1px solid rgba($black, 0.25) !important;
  border-radius: 3px !important;
}
// k-form
.k-animation-container-shown {
  box-shadow: 0 1px 6px 0 rgba($black, 0.1);
}
// accordionRow
.accordionRow {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.accordionRow .k-expander {
  float: left;
  width: 100%;
  padding: 7px 10px 7px 10px;
  background: rgba($black, 0.01);
  margin-bottom: 10px;
  box-shadow: 0 0px 3px 0 rgba($black, 0.1);
  border: 1px solid rgba($black, 0.05);
  border-radius: 5px;
  line-height: normal;
}
.accordionRow .k-expander-header:hover,
.k-expander-header.k-state-hover,
.k-expander-header.k-hover {
  background-color: transparent;
}
.accordionRow .k-expander-header {
  padding: 0;
  .k-expander-title {
    text-transform: none;
    font-weight: 500;
    color: rgba($black, 0.7);
  }
}
.k-dialog .k-multiselect {
  background: rgba($white, 0.8);
}
.accordionRow .k-expander-content {
  padding: 0;
  .k-input-md .k-input-inner,
  .k-picker-md .k-input-inner {
    padding: 3px 8px;
  }
}
.k-input-md .k-input-inner,
.k-picker-md .k-input-inner {
  padding: 3px 6px;
}
.filterBtn {
  position: fixed;
  bottom: 0;
  text-align: center;
  background: $white;
  padding: 10px 0;
  margin: 10px 0 0 0;
  width: 444px;
  z-index: 999;
  border-top: 1px solid rgba($black, 0.1);
}
.filterMain {
  float: left;
  margin: 0 0 60px 0;
}
.filterTrak-btn .k-button-group .filterButton:last-child button {
  margin: 0 0 5px 0;
}
@media screen and (max-width: 568px) {
  .dialogSide .k-dialog {
    width: 100%;
  }
}
