table tr td {
  color: #333;
  font-size: 0.875rem;
  padding: 8px;
  word-break: break-all;
}
.k-grid {
  font-family: "Poppins" !important;
  td, th {
    color: #333;
    font-size: 0.875rem;
    padding: 8px;
    border-left-color: rgba($black, 0.12);
  }
}
.k-grid-content tr:last-child > td,
.k-grid-content-locked tr:last-child > td {
  border-bottom-width: 1px;
}
.fill-row {
  background: #f0f7fb;
}
.tr-show {
  display: table-row !important;
}
.tr-player {
  display: none;
}
// .table-overflow-hedden start
.table-overflow-hedden .k-grid-header,
.k-grid-footer {
  padding-inline-end: var(--kendo-scrollbar-width, 0);
  padding-right: 0;
}
.table-overflow-hedden .k-grid-content {
  overflow: hidden;
  overflow-x: hidden;
  overflow-y: hidden;
}
// .table-overflow-hedden end
.k-clear-value {
  height: calc(12px + 12px) !important;
}
.k-input-inner {
  height: calc(14px + 14px);
}
.card-header {
  font-size: 18px;
  padding: 11px 17px 12px 17px;
}
.k-grid tbody > tr:not(.k-detail-row):hover,
.k-grid tbody > tr:not(.k-detail-row).k-state-hover,
.k-grid tbody > tr:not(.k-detail-row).k-hover {
  //background: rgba($primary, 0.06);
  background: var(--color-main-hover);
}
.highlightRow {
  background: rgba(122, 162, 191, 0.1) !important;
  &Player tr.k-detail-row {
    background: rgba(122, 162, 191, 0.1) !important;
  }
}
.rotate-direction-i {
  transform: rotate(-30deg);
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}
.borderLeftSecondChild {
  &.table-mobile {
    .k-grid {
      td, th {
        &:nth-child(2) {
          border-left: 0;
        }
      }
    }
  }
}
.borderLeftThirdChild {
  .k-grid {
    td, th {
      &:nth-child(3) {
        border-left: 0;
      }
    }
  }
}
.listRowMobile {
  display: none;
  float: left;
  width: 100%;
  padding: 10px 0 10px 0;
}
.spanLabel {
  float: left;
}
.word-break-all {
  word-break: break-all;
}
.k-pager-sizes .k-dropdownlist {
  background: transparent;
  border: 1px solid rgba($black, 0.2) !important;
  border-radius: 3px !important;
}
table .k-icon.k-i-sort-asc-small {
  position: absolute;
  right: 0;
}
.tableList {
  .k-grid {
    border: 0;
    td {
      // padding: 8px 8px 8px 8px;
      border-left: 0;
      border-top: 5px solid #f7f7f7;
      border-bottom: 5px solid #f7f7f7;
      font-size: 12px;
      line-height: initial;
      position: inherit;
      &.listMobile {
        position: relative;
      }
    }
    .k-grid-header {
      border: 0;
      background: #f7f7f7;
      th {
        padding: 8px;
        border-left: 0;
        // border-bottom: 10px solid #f7f7f7;
        background: #f7f7f7;
        color: rgba($black, 0.35);
        font-weight: 500;
        font-size: 12px;
        line-height: initial;
        vertical-align: top;
        .k-column-title {
          transition: all 0.3s;
        }
        &:hover {
          .k-column-title {
            white-space: normal;
          }
        }
      }
    }
  }
  .k-grid-pager {
    background: #f7f7f7;
    border-top: 10px solid #f7f7f7;
    padding-left: 0;
    padding-right: 0;
  }
  .activeList {
    border: 0;
    background: $white !important;
    box-shadow: 1px 0px 7px -1px rgba($black, 0.2);
    position: relative;
    transition: all 300ms ease-out;
    td {
      border-color: $white;
      transition: all 300ms ease-out;
    }
  }
}
.tableList .table-overflow-hedden .k-widget.k-grid {
  overflow: hidden;
}
.searchCol .k-input {
  background: transparent;
  border: 1px solid rgba($black, 0.12) !important;
}
.tableTitle {
  border-bottom: 1px solid rgba($black, 0.08);
  padding: 0 0 15px 0;
}
.tableList .k-grid tbody > tr:not(.k-detail-row):hover,
.k-grid tbody > tr:not(.k-detail-row).k-state-hover,
.k-grid tbody > tr:not(.k-detail-row).k-hover {
  border: 0;
  background: $white !important;
  box-shadow: 1px 0px 7px -1px rgba($black, 0.2);
  position: relative;
  transition: all 600ms ease-out;
}
.iBtn-bg {
  background: rgba(var(--color-main-rgb), 0.08);
  width: 33px;
  height: 33px;
}
.iBtn button {
  height: 33px;
  width: 33px;
}
.rowSpace .highlightRow {
  background: $white !important;
  td {
    border-bottom: 0;
  }
}
.rowSpace .k-detail-row td {
  border-top-width: 2px;
}
.gridContentOverflowY-hidden .k-grid-content {
  overflow-y: hidden;
}
.gridContentOverflowY-hidden .k-grid .k-grid-header {
  padding-right: 0;
}

// responsive
@media only screen and (min-width: 701px) and (max-width: 1280px) {
  .maxTableCol .table-overflow-hedden .k-grid-header,
  .k-grid-footer {
    width: 1502px;
  }
  .maxTableCol .table-overflow-hedden .k-widget.k-grid {
    width: 1502px;
  }
  .maxTableCol .table-overflow-hedden .k-grid .k-grid-container {
    width: 1502px;
  }
}
