//$primary: #6b6db2;

:root {
  --color-main: #6b6db2;
  --color-main-rgb: 107, 109, 178;
  --color-main-hover: rgba(107, 109, 178, 0.06);
  --color-page-selected: rgba(107, 109, 178, 0.22);
  --brand-logo-nav-height: 18px;
}


// colors black
$black: #000000;
$black-1: rgba($black, 0.03);
$black-2: rgba($black, 0.05);
$black-3: rgba($black, 0.07);
$black-4: rgba($black, 0.09);
$black-5: rgba($black, 0.1);
$black-6: rgba($black, 0.2);
$black-7: rgba($black, 0.3);
$black-8: rgba($black, 0.4);
$black-9: rgba($black, 0.5);
$black-10: rgba($black, 0.6);
$black-11: rgba($black, 0.7);
$black-12: rgba($black, 0.8);
$black-13: rgba($black, 0.9);
$black-14: rgba($black, 1);
// colors white
$white: #ffffff;
$white-1: rgba($white, 0.03);
$white-2: rgba($white, 0.05);
$white-3: rgba($white, 0.07);
$white-4: rgba($white, 0.09);
$white-5: rgba($white, 0.1);
$white-6: rgba($white, 0.2);
$white-7: rgba($white, 0.3);
$white-8: rgba($white, 0.4);
$white-9: rgba($white, 0.5);
$white-10: rgba($white, 0.6);
$white-11: rgba($white, 0.7);
$white-12: rgba($white, 0.8);
$white-13: rgba($white, 0.9);
$white-14: rgba($white, 1);

$bg-microsoft: rgba(0, 156, 233, 1);