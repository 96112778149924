// Mixin for border-radius
@mixin border-radius($side, $value) {
    @if $side == "all" {
        border-radius: $value;
    } @else if $side == "top-right" {
        border-top-right-radius: $value;
    } @else if $side == "top-left" {
        border-top-left-radius: $value;
    } @else if $side == "bottom-right" {
        border-bottom-right-radius: $value;
    } @else if $side == "bottom-left" {
        border-bottom-left-radius: $value;
    }
}

// Generate classes for different border radius
@each $value in 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 12, 15, 20, 50, 100 {
    .radius-#{$value} {
        @include border-radius("all", $value + px);
    }

    .radius-top-right-#{$value} {
        @include border-radius("top-right", $value + px);
    }

    .radius-top-left-#{$value} {
        @include border-radius("top-left", $value + px);
    }

    .radius-bottom-right-#{$value} {
        @include border-radius("bottom-right", $value + px);
    }

    .radius-bottom-left-#{$value} {
        @include border-radius("bottom-left", $value + px);
    }
}
