@media (min-width: 1845px) {
  .cardListArea .col {
    max-width: 310px;
    &.col-md-4.notificationCol {
      max-width: initial;
    }
  }
  .cardPro .cardListArea .col {
    max-width: 290px;
  }
}
// @media (min-width: 679px) {
//   .addNotificationForm {
//     max-height: 650px;
//   }
// }
@media (max-width: 1439px) {
  // .kpiList {
  //   display: block;
  //   text-align: center;
  // }
  .kpiList-a {
    // width: 50%;
    min-height: 90px;
  }
  .kpiListTr .kpiList-a {
    min-height: auto;
  }
  // .kpiListTile {
  //   float: left;
  //   width: 100%;
  //   justify-content: center;
  // }
}
@media (max-width: 1367px) {
  .wordFilterLetter {
    height: 13px !important;
  }
}
@media (max-width: 1279px) {
  .cardLabRow .detailColBox {
    top: 0 !important;
  }
  .cardLabRow ul.k-tabstrip-items.k-reset {
    padding-left: 0 !important;
  }
}
@media (max-width: 1044px) {
  .tabLeftPadding-0 .k-tabstrip-items .k-item .k-link {
    padding-left: 3px !important;
  }
  .tabLabel .k-tabstrip-items .k-item:last-child .k-link {
    padding-right: 3px !important;
  }
}
@media (max-width: 1024px) {
  .wordFilterLetter {
    height: 10px !important;
  }
}
@media (max-width: 1000px) {
  .callDetailBody .callDetailHdr {
    display: block !important;
  }
  .callDetailBody .detailHeader {
    display: block !important;
  }
  .callDetailBody .listInr-detail {
    padding: 6px 0 12px 0;
    flex-wrap: wrap;
  }
  .callDetailBody .listInr-detail .liDetail {
    margin: 10px 0 10px 0;
  }
  .detailSection .detailRow {
    display: block !important;
  }
  .detailSection .detailRow .detailCol {
    padding-left: 0 !important;
    margin-left: 0 !important;
    border: none !important;
  }
  .tileDataRow.d-flex.justify-content-between {
    display: block !important;
  }
  .tileDataCol {
    margin-bottom: 2px !important;
  }
  .mainBox {
    display: block !important;
  }
  .rightBox.w-25 {
    width: 100% !important;
  }
  .rightBox {
    width: 100% !important;
  }
  .detailBox {
    width: 100% !important;
    padding-left: 0 !important;
  }
  .docSpliter .k-widget.k-splitter {
    width: 100% !important;
    display: block;
  }
  .docSpliter .k-splitbar.k-splitbar-horizontal {
    display: none;
  }
  .tabLabel .k-tabstrip-items .k-link {
    padding-left: 0;
    padding-right: 0;
  }
  .tabLabel .k-tabstrip-items-wrapper .k-item {
    margin-right: 0;
  }
  .tabLabel .navSidePro .k-tabstrip-items-wrapper .k-item {
    margin-right: 0 !important;
  }
  .k-pager-sizes {
    display: none;
  }
}
@media (max-width: 972px) {
  .boxUsers .cardWordFilter {
    top: 200px;
  }
}
@media (max-width: 800px) {
  .callListInner {
    display: block;
  }
  .callListBoxFilter {
    box-shadow: 0 1px 6px 0 rgba($black, 0.1);
    position: fixed;
    top: 56px;
    left: -15px;
    z-index: 9;
    height: 100%;
  }
}
@media (max-width: 767px) {
  .userLabels.float-right.text-right {
    text-align: right !important;
  }
  .detailColOuterLabel {
    padding-left: 0 !important;
    border: none !important;
  }
  .cardLab {
    padding-left: 5px !important;
  }
  .k-pager-info {
    display: none;
  }
}
@media (max-width: 768px) {
  .chipBox.d-flex.align-items-center.float-right {
    float: left !important;
  }
  .noteFormRow.rowNoteName {
    display: block !important;
  }
  .boxUserGroups .cardWordFilter {
    top: 160px;
    right: 5px;
    margin: 0;
  }
  .contentPush {
    padding: 15px 5px 0 10px;
  }
  .userListingData, .userListingAllUsers {
    min-width: 90%;
  }
  .k-dialog-wrapper.dialog-sm .k-dialog {
    width: 92%;
  }
  .mainDrawerInner .k-widget.k-drawer.k-drawer-start {
    flex-basis: 50px !important;
  }
  .miniButton {
    display: none;
  }
  .contentHeadTop.d-flex.justify-content-between.align-items-center {
    flex-wrap: wrap;
  }
  .contentHeadAction {
    padding: 7px 0 5px 0;
  }
  .contentHeadAction button .k-button-text span {
    display: none;
  }
  .contentHeadAction button {
    padding: 0 5px;
  }
  .listOverflowDvc {
    padding: 0 !important;
  }
  .cardPro .tabLabel {
    width: 100%;
  }
}
@media (max-width: 700px) {
  // css start
  .filterChipList {
    display: none;
  }
  .mobile-heading-view-by {
    display: block !important;
    font-size: 16px;
    font-weight: bold;
    width: 100%;
  }
  .insightViewChart {
    display: none;
  }
  .settingData.k-custom-tab.p-t-20.p-b-20.systemSet.settingDataMobile {
    padding: 0 15px;
  }
  .tabGroupDropdown {
    display: block;
    padding: 0px 0px 10px 0px;
  }
  .tabGroupDropdown span.k-dropdownlist.k-picker.k-picker-md.k-rounded-md.k-picker-solid {
    border: 1px solid rgba($black, 0.1) !important;
    border-radius: 4px !important;
    background: rgba($black, 0.03);
    color: rgba($black, 0.7);
    font-size: 14px !important;
    font-weight: normal;
    box-shadow: 0 1px 3px 1px rgba($black, 0.05);
  }
  .tabGroupData {
    display: none !important;
  }
  .blr-msg .blurMsgInr {
    margin: -6px 0 0 0;
  }
  .settingData .k-tabstrip {
    .k-vbox,
    .k-vstack {
      display: block;
      flex-flow: wrap;
      .k-tabstrip-items {
        //flex-direction: initial;
        padding-right: 0 !important;
        .k-item {
          color: rgba($black, 0.54);
          margin: 0 6px 8px 0 !important;
        }
      }
    }
    .k-content {
      padding: 10px 0 5px 2px;
    }
    .spkTrk {
      display: block;
    }
  }
  .track .legends {
    display: block !important;
    ul {
      display: flex !important;
      float: left;
      padding: 0 0 10px 0;
      li {
        margin: 0;
        padding: 0;
      }
    }
  }

  .table-mobile thead {
    display: none;
  }
  .table-mobile td {
    display: none !important;
  }
  .cardEffect .card-header {
    display: block !important;
    .buttons-container {
      padding: 10px 0 4px 0 !important;
      justify-content: left;
    }
  }
  .mobile-contain {
    padding: 15px 0 0 0 !important;
  }
  .listMobile {
    width: 100vw !important;
  }
  .table-mobile .listMobile {
    display: block !important;
    width: calc(100vw - 100px) !important;
    padding-right: 40px;
    position: relative;
  }
  .table-mobile .sysSettingsListMobile {
    display: block !important;
    width: 86vw !important;
    position: relative;
  }
  .table-mobile .k-detail-cell {
    display: block !important;
    width: 90vw !important;
  }
  .table-mobile .k-grid-norecords td {
    display: block !important;
    width: 100vw !important;
  }
  .listRowMobile {
    display: block !important;
  }
  .iconMobileDetail {
    position: absolute;
    top: 2px;
    right: 40px;
  }
  .tableList .k-grid td.listMobile .iconMobileDetail {
    position: absolute;
    top: 7px;
    right: 5px;
  }
  .deviceContent {
    padding: 20px 0 0 0 !important;
  }
  .k-custom-tab .k-tabstrip-items-wrapper {
    padding: 0 15px;
    border: none;
  }
  .gridContentOverflowY-hidden .k-grid-content {
    overflow-x: hidden;
  }
  .mainBox .detailBox {
    padding-left: 5px !important;
  }
  // css end
}
@media (max-width: 660px) {
  .k-dialog-wrapper.dialogShareList .k-dialog {
    max-height: 90vh !important;
  }
  .dialogCol {
    width: 100% !important;
  }
  .detailColBoxOt {
    width: 100% !important;
  }
  .shareList {
    min-width: 100% !important;
  }
}
@media (max-width: 600px) {
  .dropBox {
    padding: 0 0 5px 0;
  }
  .codeBoxActionBtn {
    width: 100%;
    text-align: right;
  }
  .wordFilterTitle {
    font-size: 12px;
  }
  .boxUsers .cardWordFilter {
    right: 5px;
    background: transparent;
    margin: 0;
  }
  .tabLabel {
    width: 90%;
  }
  .cardListArea {
    padding: 15px 20px 15px 25px;
  }
  .boxUserGroups .cardBrand {
    width: 90%;
  }
  .boxUserGroups.fullBoxCard .cardBrand {
    width: 100%;
  }
  .settingData .k-tabstrip-left {
    flex-direction: column;
  }
}
@media (max-width: 500px) {
  // css start
  .btngroupdata {
    justify-content: center;
    display: flex;
    float: none !important;
  }
  .header-contain .hdrTrk-logo img {
    max-width: 115px;
  }
  .cardEffect.callDetailBody .buttons-container.hov-transparent {
    display: flex !important;
  }
  .dropUlNotification {
    width: 320px;
    max-width: 320px;
  }
  .boxUserGroups .contentBody {
    width: 100%;
    float: left;
  }
  .cardListBlock .keywordTrk {
    font-size: 11px;
  }
  .tabLabel .k-tabstrip-items-wrapper .k-item {
    margin-bottom: 5px;
    .k-link {
      padding-bottom: 3px !important;
      padding-top: 3px !important;
    }
  }
  .tabLabel .k-tabstrip-top > .k-tabstrip-items-wrapper > .k-tabstrip-items {
    display: block;
    padding-bottom: 3px;
    padding-top: 5px;
  }
  .boxUserGroups .cardBrand {
    width: 100%;
  }
  .tabLabel {
    width: 93%;
  }
  .langText {
    display: none;
  }
  .formGroup {
    display: block !important;
    .k-form {
      min-width: 100%;
    }
  }
  .filterBtn {
    width: 98%;
  }
  .or-divider span::after, .or-divider span:before {
    width: 66%;
  }
  .cardEffect .card-header .buttons-container .searchBox input {
    width: 100% !important;
  }
  .gridAction {
    display: block !important;
  }
  .actionLabel {
    padding-bottom: 15px;
  }
  .listMobile .k-rating-container .k-rating-item {
    padding: 0 3px 0 0;
    .k-icon {
      font-size: 20px;
    }
  }
  .table-mobile .k-pager-numbers-wrap {
    display: none;
  }
  .cardMobile .card-header .k-button-text .btnSpan {
    display: none;
  }
  .cardMobile .card-header .buttons-container {
    position: relative;
  }
  .cardMobile .card-header .searchBox {
    margin-right: 0 !important;
  }
  .cardMobile .card-header .buttons-container .k-button {
    // padding: 0;
    // width: 27px;
    position: absolute;
    top: -21px;
    right: 0;
    height: 27px !important;
  }
  .formAdd {
    width: 100% !important;
  }
  .profileSrch {
    width: 100%;
    margin-top: 5px !important;
  }
  .addGroupMultiProfile {
    display: block !important;
  }
  .authProfileRow {
    display: block !important;
  }
  .btnGroupCol {
    display: block !important;
  }
  .periodFilters {
    padding: 0 0 12px 0;
  }
  .iconFilters {
    justify-content: end;
  }
  .callListBoxFilter {
    width: 100% !important;
    min-width: 100% !important;
  }
  .filterExpd .filterTrak {
    max-height: 300px;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .filterUserLabel {
    height: auto !important;
    width: 100% !important;
    button {
      position: relative !important;
      width: auto !important;
      top: -5px !important;
      left: -6px !important;
      right: auto !important;
    }
  }
  .playerBtnGroup {
    display: block;
    .playerBtn {
      float: left;
    }
  }
  .contentPush {
    padding: 13px 30px 0 0px !important;
  }
  // css end
}
@media (max-width: 460px) {
  .systemSet .trkCol-dot {
    display: none !important;
  }
  .systemSet .trk-container .trk-inr .trk-row .trkCol .comboBoxInput {
    width: 100% !important;
  }
  .systemSet .trk-container .trk-inr .trk-row .trkCol .textInput {
    width: 100% !important;
  }
  .systemSet .trk-container .trk-inr .trk-row .trkCol .trkCol-h {
    min-width: 100px !important;
    max-width: 100px;
    font-size: 13px;
    font-weight: bold !important;
    padding-right: 5px;
  }
  .trk-container.lcsBox .trk-inr .trk-row .trkCol {
    display: flex;
    justify-content: space-between;
  }
  .systemSet .trk-container.lcsBox .trk-inr .trk-row .trkCol .trkCol-h {
    min-width: 160px !important;
    max-width: 160px;
    font-size: 13px;
    font-weight: bold !important;
    padding-right: 5px;
  }
  .systemSet .trk-container.lcsBox .trk-inr .trk-row .trkCol .trkCol-p {
    align-items: center;
    display: flex;
  }
  .systemSet .trk-container.emlBox .trk-inr .trk-row .trkCol .trkCol-h {
    min-width: 100% !important;
    max-width: 100%;
    font-size: 13px;
    font-weight: bold !important;
    padding-right: 5px;
  }
}
@media (max-width: 409px) {
  .blr-msg .blurMsgInr {
    margin: -6px 0 0 0;
    width: 300px;
    text-align: center;
  }
  .k-custom-tab .k-tabstrip > .k-tabstrip-items-wrapper > .k-tabstrip-items .k-item {
    width: 100%;
    margin-right: 0 !important;
    .k-link {
      max-width: 100%;
    }
  }
  .settingData .k-tabstrip .k-vbox .k-tabstrip-items,
  .settingData .k-tabstrip .k-vstack .k-tabstrip-items {
    padding-right: 5px;
  }
}
@media (max-width: 370px) {
  .navSidePro .k-tabstrip-left > .k-tabstrip-items-wrapper {
    min-width: 100px !important;
  }
  .titleWithTab .d-flex.justify-content-between.align-items-center {
    display: block !important;
    .leftProControl {
      padding-top: 10px !important;
    }
  }
  .playerBtnGroup .PlayerButton {
    height: 28px;
    min-width: 28px;
  }
  .filterMain {
    max-height: 80vh;
    overflow-y: scroll;
    overflow-x: hidden;
    padding-bottom: 40px;
  }
}
@media (max-width: 359px) {
  // css start
  .cardEffect .card-header {
    display: block !important;
  }
  .formLabel {
    flex-direction: column-reverse;
  }
  a.forgotPassword.btn-link.fs-14 {
    float: left;
    width: 100%;
  }
  .or-divider span::after, .or-divider span:before {
    width: 36%;
  }
  .k-custom-tab .k-tabstrip > .k-tabstrip-items-wrapper > .k-tabstrip-items .k-item {
    width: 100%;
  }
  .k-custom-tab .k-tabstrip > .k-tabstrip-items-wrapper > .k-tabstrip-items .k-item .k-link {
    max-width: 100% !important;
  }
  .header-contain {
    padding: 7px 30px 7px 10px;
  }
  // css end
}
@media (max-width: 1278px) {
  .userLabels.text-right {
    text-align: left !important;
  }
  .userRowLbl {
    flex-wrap: wrap;
  }
}
@media only screen and (max-width: 1420px) and (min-width: 1335px) {
  .cardListBlock .tblUsr .contact-list-icon {
    width: 28px;
    height: 28px;
  }
}
@media only screen and (max-width: 1336px) and (min-width: 1279px) {
  .cardListBlock .tblUsr .contact-list-icon {
    width: 26px;
    height: 26px;
  }
}
@media (min-width: 500px) {
  .dropUlNotification {
    width: 440px;
    max-width: 440px;
  }
}
@media screen and (max-height: 500px) {
  .k-dialog-wrapper.dialogShareList .k-dialog {
    max-height: 90vh !important;
  }
}
@media screen and (max-height: 400px) {
  .boxUsers .cardWordFilter {
    top: 45px !important;
    right: 15px !important;
  }
  .wordFilterLetter {
    height: 8px !important;
    font-size: 10px;
  }
  .contentPush {
    width: 91%;
  }
  .tabLeftPadding-0 .k-tabstrip-items .k-item .k-link {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .tabLabel .k-tabstrip-items-wrapper .k-item {
    margin-bottom: 8px;
  }
}
