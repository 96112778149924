.btn-white {
    &:hover {
        color: #212529;
        text-decoration: none;
        background: #f0f0f0;
    }
}

.evalFullscreen {
    &:hover {
        transition: 0.2s;
        color: #8a2be2;
        transform: scale(2);
        z-index: 1;
        position: absolute;
        top: 4.5em;
        right: 0.5em;
    }
    transition: 0.2s;
    color: #808080;
    transform: scale(1.7);
    z-index: 1;
    position: absolute;
    top: 4.2em;
    right: 0.2em;
}
.playerFloat {
    max-width: 900px;
    display: none;
    transition: all 0.3s;
    margin: 0 auto 0.75rem auto;
    padding: 0.25em 0;
    background: $white;
    border-radius: 1em;
    box-shadow: 0px 0px 8px rgba($color: #000000, $alpha: 0.1);
}

.wrapper {
    transition: all 0.3s;
    display: flex;
    height: 100%;
}

.evalScoreForm {
    overflow: auto;
    border-radius: 0.5rem;
    flex: 1 1 70%;
    min-width: 464px;
    margin-right: 0.5rem;
    transition: all 0.3s;
}
.evalTimeStamp {
    line-height: 1;
}
.evalNavItem {
    border: solid 1px rgba($color: #aaaaaa, $alpha: 0.5);
    margin-right: 0.25em;
    border-radius: 0.25em 0.25em 0 0;
}
.evalTabContent {
    padding: 0.5em;
    border: solid 1px rgba($color: #aaaaaa, $alpha: 0.5);
    border-radius: 0 0.25em 0.25em 0.25em;
}
.largeScorePan {
    &:hover {
        transition: all 0.3s;
        button {
            color: #8a2be2;
        }
        transform: scale(1.6);
        position: absolute;
    }
    transition: all 0.3s;
    padding: 0.5em;
    transform: scale(1.4);
    position: absolute;
    button {
        color: rgb(170, 170, 170);
    }
}

.largeScorePan1 {
    &:hover {
        transition: all 0.3s;
        color: #8a2be2;
        margin-right: -5px;
        transform: scale(1.3);
    }
    transition: all 0.3s;
    color: rgb(170, 170, 170);
}

//footer eval form

.evalFooterBtn {
    .autoSaveTimer {
        span {
            #autoSaveTimer {
                font-size: 0.7rem;
                color: #008000;
            }
            font-size: 0.6rem;
        }
    }
}

//side pan
.evalSidePane {
    border-radius: 0.5rem;
    flex: 1 1 30%;
    margin-left: 0.5rem;
    transition: all 0.3s;
}

.evalScoreForm.active {
    transition: all 0.3s;
    border-radius: 0.5rem;
    flex: 1 1 100%;
    min-width: 464px;
    margin-right: 0;
}

.evalSidePane.active {
    transition: all 0.3s;
    display: none;
}

.evalScoreForm2.active2 {
    transition: all 0.3s;
    display: none;
}

.evalSidePane2.active2 {
    transition: all 0.3s;
    overflow: auto;
    border-radius: 0.5rem;
    flex: 1 1 100%;
    margin-left: 0.5rem;

    margin-left: 0;
}

.largeSidePan {
    &:hover {
        transition: all 0.3s;
        button {
            color: #8a2be2;
        }
        top: 4px;
        left: -10px;
        transform: scale(1.6);
        position: absolute;
    }
    transition: all 0.3s;
    padding: 0.5em;
    transform: scale(1.4);
    position: absolute;
    top: 4px;
    left: -15px;
    button {
        color: rgb(170, 170, 170);
    }
}

//media query
@media (max-width: 700px) {
    .wrapper {
        flex-direction: column;
    }
    .evalScoreForm {
        border-radius: 0.5rem;
        flex: 1 1 100%;
        min-width: 464px;
        margin-right: 0;
        transition: all 0.3s;
    }

    .evalSidePane {
        transition: all 0.3s;
        overflow: auto;
        border-radius: 0.5rem;
        flex: 1 1 100%;
        margin-top: 0.5em;
        margin-left: 0;
    }
}
