// user control
.mainDrawer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.miniButton {
  position: fixed;
  z-index: 9;
  left: 5px;
  top: 60px;
}
.mainDrawerInner {
  height: 100%;
  .k-drawer-container {
    height: 100%;
  }
}
.navPushDown .k-widget.k-drawer.k-drawer-start {
  padding: 43px 0 0 0;
  background: rgba($black, 0.03);
  border: none;
  height: 100%;
  transition: all 200ms ease 0s;
}
.navPush .k-drawer-item.k-selected {
  background-color: rgba(var(--color-main-rgb), 0.15);
  .k-item-text {
    color: var(--color-main) !important;
    font-weight: 500;
  }
  .k-icon {
    color: var(--color-main) !important;
  }
}
.navPush .k-drawer-container .k-drawer-items .k-item-text {
  color: rgba($black, 0.5);
  padding-right: 8px;
}
.k-drawer-item {
  border-right: 2px solid transparent;
}
.navPush .k-drawer-item.k-selected {
  border-right: 2px solid var(--color-main);
}
.navPush .k-drawer-container .k-drawer-items .k-icon {
  color: rgba($black, 0.4);
  font-size: 18px;
}
.miniButton button {
  color: rgba($black, 0.4);
  line-height: 0;
  padding: 5px 6px 5px 7px;
  .k-button-text {
    font-size: 25px;
    float: left;
  }
  .k-button-icon.k-icon.k-i-menu {
    display: none;
  }
}
.navPush .k-drawer-item {
  min-height: 45px;
}
.navPush .k-button:hover::before,
.k-button.k-hover::before {
  opacity: 0;
}
.navPush .k-button:focus::before,
.k-button.k-hover::before {
  opacity: 0;
}
.contentPush {
  padding: 15px 30px 0 10px;
  height: 100%;
}
.mainDrawerInner .k-drawer-content {
  height: 100%;
  width: 100%;
}
.contentHeadTop {
  margin: 0 0 0 20px;
}
.contentHeadTab .k-tabstrip-items-wrapper {
  margin: 0 0 0 20px;
}
.contentPushArea {
  float: left;
  width: 100%;
  height: 100%;
}
.headBottomBdr {
  border-bottom: 1px solid rgba($black, 0.08);
}
.tabLeftPadding-0 .k-tabstrip-items .k-item:first-child .k-link {
  padding-left: 3px !important;
}
.tabLabel .k-tabstrip-items .k-link {
  text-transform: initial;
  padding: 14px 15px !important;
  font-size: 13px !important;
}
.tabLabel .k-tabstrip-items-wrapper .k-item.k-active::after {
  border-color: var(--color-main);
}
.tabLabel .k-tabstrip-items-wrapper .k-item:active,
.k-tabstrip-items-wrapper .k-item.k-active,
.k-tabstrip-items-wrapper .k-item.k-selected {
  color: var(--color-main);
}
.tabBadge {
  padding: 0 5px;
  background: rgba(144, 115, 127, 0.12);
  font-size: 11px;
  font-weight: 500;
  border-radius: 50px;
}
.tabLabel .k-content {
  padding: 0;
}
.cardBrand {
  float: left;
  width: 100%;
  height: 100%;
  padding: 5px 0 20px 0;
  display: flex;
  justify-content: space-between;
  max-height: 75vh;
  overflow-y: auto;
  overflow-x: hidden;
}
.cardListBlock {
  box-shadow: 0 1px 5px 0 rgba($black, 0.1);
  background: $white;
  // margin: 0 0 16px 0;
  float: left;
  width: 100%;
  height: 100%;
  position: relative;
  transition: all 400ms ease 0s;
}
.cardRows {
  float: left;
  width: 100%;
  padding: 10px 13px 13px 13px;
  height: 100%;
}
.cardColumn {
  float: left;
  width: 100%;
}
.colUser {
  float: left;
  width: 100%;
}
.labelBadge {
  float: left;
  width: 100%;
  span {
    background: rgba($black, 0.1);
    font-size: 12px;
    border-radius: 4px;
    padding: 1px 6px;
  }
}
.cardListArea {
  float: left;
  width: 100%;
  padding: 15px 60px 15px 30px;
  .col {
    padding: 0 8px 16px 8px;
  }
}
.contentPushArea .k-animation-container {
  float: left;
  width: 100%;
  height: 100%;
}
.lblGroup {
  padding-left: 35px;
}
.contentPush > div {
  height: 100%;
}
.minTextLabel {
  float: left;
  width: 100%;
  font-size: 12px;
}
.cardListBlock:hover {

  box-shadow: 0 1px 15px 0 rgba($black, 0.25);
  .blockIconButton {
    opacity: 1;
  }
  .topUsrAreaPic {
    max-width: calc(100% - 86px);
  }
}
.blockIconButton {
  position: absolute;
  top: 5px;
  right: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  opacity: 0;
  transition: all 300ms ease 0s;
  background: rgba($white, 1);
  padding: 0 0 0 5px;
}
.blockIconButton .k-switch-md .k-switch-track {
  width: 27px;
  height: 17px;
}
.blockIconButton .k-switch-md.k-switch-off .k-switch-thumb-wrap {
  left: 10px;
}
.blockIconButton .k-switch-md .k-switch-thumb {
  width: 14px;
  height: 13px;
}
.blockIconButton .switchButton .k-switch-md {
  width: 27px;
  height: 17px;
}
.blockIconButton .k-switch-md.k-switch-on .k-switch-thumb-wrap {
  left: calc(100% - 8px - 1px);
}
.btnBlocks {
  margin: 0 0 0 10px;
  &:first-child {
    margin: 0;
  }
}
.btnBlock-a {
  color: rgba($black, 0.4);
}
.btnBlock-a :hover {
  color: var(--color-main);
}
.cardWordFilter {
  min-width: 30px;
  text-align: center;
  margin: 0 0 0 40px;
  // background: #f7f7f7;
  padding: 10px 0 0 0;
  position: fixed;
  right: 34px;
  top: 154px;
  z-index: 9;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.boxUserGroups .cardWordFilter {
  top: 130px;
}
.wordFilterTitle {
  float: left;
  width: 100%;
  color: var(--color-main);
  padding: 0;
}
.wordFilterBox {
  float: left;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  width: 23px;
  justify-content: center;
}
.wordFilterLetter {
  color: rgba($black, 0.4);
  margin: 1px 0;
  background: transparent;
  border-radius: 50px;
  font-size: 11px;
  font-weight: 500;
  cursor: pointer;
  transition: all 300ms ease 0s;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 21px;
  height: 16px;
  &.activeLetter {
    color: var(--color-main);
    margin: 2px 0;
    background: rgba(var(--color-main-rgb), 0.15);
    border-radius: 50px;
    font-size: 12px;
    font-weight: 500;
    line-height: 0;
    transform: scale(1.1);
  }
  &:hover {
    background: rgba(var(--color-main-rgb), 0.1);
    transform: scale(1.4);
  }
}
a:hover {
  color: var(--color-main);
}
.mx-td-spn65 {
  max-height: 24px;
  text-overflow: ellipsis;
  max-width: 65%;
  min-width: 90px;
  white-space: nowrap;
  cursor: default;
  overflow: hidden;
  transition: max-height 1.5s cubic-bezier(0, 1.05, 0, 1);
}
.contentPushArea .k-tabstrip {
  height: 100%;
}
.contentPushArea .k-content {
  height: 100%;
}
.contentPushArea .k-animation-container .k-child-animation-container {
  height: 100%;
}
.contentPushArea .k-animation-container .k-child-animation-container > div {
  height: 100%;
}
.cardListBlock .tblUsr .contact-list-icon {
  width: auto;
  min-width: 30px;
  height: 30px;
  padding: 0 3px;
}
.cardListBlock .tblUsr.moreUsr .topUsrAreaPic.transparentBtnHover .contact-list-icon {
  padding: 0;
}
.cardListBlock .tblUsr .topUsrAreaPic-i .contact-list-icon .contact-list-icon-txt {
  font-size: 13px;
}
.userRowLbl {
  float: left;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.dvcList {
  float: left;
  width: 100%;
  padding: 5px 0 0 0;
}
.dvcListCol {
  float: left;
  width: 100%;
  box-shadow: 0 1px 3px 2px rgba($black, 0.05);
  border-bottom: 3px solid rgba(var(--color-main-rgb), 0.4);
  padding: 10px 15px 16px 15px;
  border-radius: 4px;
  margin: 0 0 15px 0;
  background: rgba(var(--color-main-rgb), 0.01);
}
.dvcPointLg {
  float: left;
  width: 100%;
}
.dvcPointLgRow {
  float: left;
  display: flex;
  width: 100%;
  padding: 5px 0 5px 0;
  border-bottom: 1px solid rgba($black, 0.05);
  font-size: 13px;
}
.point-p {
  min-width: 115px;
  max-width: 115px;
  color: rgba($black, 0.4);
}
.point-dot {
  min-width: 15px;
}
.dvcPointLgRow:last-child {
  border: none;
  padding-bottom: 0;
}
.wordBreakAll {
  word-break: break-all;
}
.cardPro .cardListArea {
  padding: 10px 11px 10px 11px;
}
.dvcPointLg.pointSm .dvcPointLgRow {
  font-size: 11px;
}
.cardPro .cardListBlock:hover {
  box-shadow: 0 1px 10px 0 rgba($black, 0.17);
}
// user control end
