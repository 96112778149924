// transcript new
.btn-soundOnOff:focus {
  box-shadow: none !important;
}
.btn-playPause:focus {
  box-shadow: none !important;
}
.btn-analytics {
  transition: all 0.3s;
  box-shadow: 0px 0px 6px -1px rgba($black, 0.2);
  color: #0000cd;
  text-shadow: 0px 2px 4px rgba(173, 0, 144, 0.5);
  &:hover {
    transition: all 0.3s;
    box-shadow: 0px 4px 12px -1px rgba($black, 0.25);
  }
  &:focus {
    transition: all 0.3s;
    background: #8a2be2;
    box-shadow: 0px 0px 12px -1px rgba($black, 0.25);
    color: rgb(255, 255, 255);
    text-shadow: 0px 3px 3px rgba($white, 1);
  }
}
.hide1 {
  display: none;
}
.transcription1 {
  top: 62vh;
}
.transcription2 {
  top: 8vh;
}
.floatAnalytics {
  z-index: 100 !important;
  padding: 0.5rem 1rem 0 1rem;
  position: fixed;
  width: 50%;
  top: 0px;
  transform: scale(0.8);
  background: $white;
  border-radius: 1rem;
  box-shadow: 0px 0px 10px -4px rgba($black, 0.2);
}
/* transcript */
.spkTrk {
  float: left;
  width: 100%;
  padding: 0 0 20px 0;
  margin: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  text-align: justify;
  opacity: 0.4;
  &.active {
    opacity: 1;
    .spkTrk-para {
      border-left: 2px solid rgba($black, 0.35);
      color: #333;
    }
  }
}
.spkTrk-h {
  width: 95px;
  font-size: 14px;
  font-weight: 600;
  white-space: nowrap;
  margin-right: 50px;
}
.spkTrk-txt {
  width: 95%;
}
.spkTrk-time {
  font-size: 12px;
  padding: 0;
}
.spkTrk-para {
  padding: 0 0 0 15px;
  color: #333;
  line-height: 1.5em;
  font-size: 14px;
  border-left: 2px solid $white;
  &focus-visible {
    outline: none;
  }
}
