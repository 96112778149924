.rating-vals .k-rating.k-widget {
    border: none;
}
.rating-vals .k-rating-item.k-hover, 
.k-rating-item.k-state-hover, 
.k-rating-item:hover {
    color: #FFD600;
    cursor: pointer;
}
.rating-vals .k-rating-item.k-selected, 
.k-rating-item.k-state-selected {
    color: #FFD600;
}