@import "/src/assets/style/variables";
.detailSection {
  float: left;
  width: 100%;
  .detailRow {
    float: left;
    width: 100%;
    display: flex;
    justify-content: space-between;
    .detailCol:first-child {
      margin: 0 0 20px 0;
      padding: 0;
      border: none;
    }
    .detailCol {
      float: left;
      width: 100%;
      border-left: 1px solid rgba($black, 0.06);
      padding: 0 0 20px 35px;
      margin: 0 0 20px 35px;
    }
  }
}
.tabContentWidth-100 .k-animation-container {
  width: 100%;
}
.tabContentP-0 .k-content.k-active {
  padding: 0;
}
.viewBoxRow {
  display: flex;
  justify-content: space-between;
  float: left;
  width: 100%;
}
.detailColBox {
  float: left;
  width: 100%;
}
.formBox {
  float: left;
  width: 100%;
}
.formBoxRow {
  float: left;
  width: 100%;
}
.detailColTitle {
  float: left;
  width: 100%;
}
.recordingpolicy-dropdown{
  margin:10px 0;
  border: none;
  outline: 1px solid rgb(206, 206, 206);
  background-color: $white;
  padding-left:10px;
  height:28px;
  color: $black;
  opacity: 0.6;
  border-radius: 3px !important;
}