// Mixin for font-size
@mixin font-size($value) {
    font-size: $value !important;
}

// Generate font-size classes
@for $i from 10 through 30 {
    .fs-#{$i} {
        @include font-size(#{$i}px);
    }
}
