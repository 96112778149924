// Legend Colours
$agent: #0064e5;
$customer: #068f43;
$cross-talk: #ffcb21;
$redacted: #767676;
$silence-both: #ff2156;
$Perfection: #b9d5fa;
$greetings: #ac0095;
$knowledge: #00d85d;
$speaker-0: #2b982b;
$speaker-1: #0080ff;
$speaker-2: #bf00ff;
$speaker-3: #ff00bf;
$speaker-4: #ff8000;

.top-player {
  .call-analytics-filter {
    max-width: 550px;
  }
  .col-2 {
    min-width: 150px;
    max-width: 150px;
  }
  .legends {
    li {
      font-size: 11px;
      margin: 3px 10px 0 0;
      padding-bottom: 4px;
      line-height: 15px;
    }
    &-bg {
      width: 5px;
      display: inline-block;
      border-radius: 50%;
      height: 5px;
      background-color: $black;
      position: relative;
      margin-right: 2px;
      top: 0px;
      &.agent {
        background-color: $agent;
      }
      &.customer {
        background-color: $customer;
      }
      &.speaker-0 {
        background-color: $speaker-0;
      }
      &.speaker-1 {
        background-color: $speaker-1;
      }
      &.speaker-2 {
        background-color: $speaker-2;
      }
      &.speaker-3 {
        background-color: $speaker-3;
      }
      &.speaker-4 {
        background-color: $speaker-4;
      }
      &.cross-talk {
        background-color: $cross-talk;
      }
      &.redacted {
        background-color: $redacted;
      }
      &.silence-both {
        background-color: $silence-both;
      }
      &.Perfection {
        background-color: $Perfection;
      }
      &.greetings {
        background-color: $greetings;
      }
      &.knowledge {
        background-color: $knowledge;
      }
    }
  }
  .track {
    &-single {
      position: relative;
      width: 100%;
      display: block;
      height: 5px;
      &::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 5px;
        background-color: #eee;
        left: 0;
        top: 0;
        z-index: 0;
      }
      span {
        height: 5px;
        display: inline-block;
        background-color: $black;
        position: absolute;
        z-index: 1;
        border-radius: 4px;
        &.agent {
          background-color: $agent;
        }
        &.customer {
          background-color: $customer;
        }
        &.speaker-0 {
          background-color: $speaker-0;
        }
        &.speaker-1 {
          background-color: $speaker-1;
        }
        &.speaker-2 {
          background-color: $speaker-2;
        }
        &.speaker-3 {
          background-color: $speaker-3;
        }
        &.speaker-4 {
          background-color: $speaker-4;
        }
        &.cross-talk {
          background-color: $cross-talk;
        }
        &.redacted {
          background-color: $redacted;
        }
        &.silence-both {
          background-color: $silence-both;
        }
        &.Perfection {
          background-color: $Perfection;
        }
        &.greetings {
          background-color: $greetings;
        }
        &.knowledge {
          background-color: $knowledge;
        }
      }
    }
  }
  .legends_PUM {
    li {
      font-size: 10px;
      margin: 0 1em 0 0;
      padding-bottom: 6px;
      color: #808080;
    }
    &-bg {
      width: 12px;
      display: inline-block;
      height: 2px;
      background-color: $black;
      position: relative;
      top: 0px;
      &.agent {
        background-color: $agent;
      }
      &.customer {
        background-color: $customer;
      }
      &.speaker-0 {
        background-color: $speaker-0;
      }
      &.speaker-1 {
        background-color: $speaker-1;
      }
      &.speaker-2 {
        background-color: $speaker-2;
      }
      &.speaker-3 {
        background-color: $speaker-3;
      }
      &.speaker-4 {
        background-color: $speaker-4;
      }
      &.cross-talk {
        background-color: $cross-talk;
      }
      &.redacted {
        background-color: $redacted;
      }
      &.silence-both {
        background-color: $silence-both;
      }
      &.Perfection {
        background-color: $Perfection;
      }
      &.greetings {
        background-color: $greetings;
      }
      &.knowledge {
        background-color: $knowledge;
      }
    }
  }

  .track_PUM {
    small {
      font-weight: 600;
      color: #696969;
    }
    margin-bottom: 20px;
    &-single {
      position: relative;
      width: 100%;
      display: block;
      height: 10px;
      &::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 6px;
        background-color: #eee;
        left: 0;
        top: 0;
        z-index: 0;
      }
      span {
        height: 6px;
        display: inline-block;
        background-color: $black;
        position: absolute;
        z-index: 1;
        border-inline: 1px solid white;
        &.agent {
          background-color: $agent;
        }
        &.customer {
          background-color: $customer;
        }
        &.speaker-0 {
          background-color: $speaker-0;
        }
        &.speaker-1 {
          background-color: $speaker-1;
        }
        &.speaker-2 {
          background-color: $speaker-2;
        }
        &.speaker-3 {
          background-color: $speaker-3;
        }
        &.speaker-4 {
          background-color: $speaker-4;
        }
        &.cross-talk {
          background-color: $cross-talk;
        }
        &.redacted {
          background-color: $redacted;
        }
        &.silence-both {
          background-color: $silence-both;
        }
        &.Perfection {
          background-color: $Perfection;
        }
        &.greetings {
          background-color: $greetings;
        }
        &.knowledge {
          background-color: $knowledge;
        }
      }
    }
  }
  .sentiments {
    height: auto;
    margin-bottom: 16px;
    .k-chart {
      height: 100%;
    }
  }
  .emotions {
    margin-bottom: 30px;
    position: relative;
    width: 100%;
    display: block;
    height: 24px;
    z-index: 1;
    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 10px;
      background-color: #eee;
      left: 0;
      top: 0;
      z-index: 0;
    }
    &-single {
      z-index: 1;
      position: absolute;
      i {
        background-color: $white;
        // border: 1px solid $white;
        color: #ffcb21;
        border-radius: 50%;
        font-size: 24px;
        display: block;
      }
    }
  }
  .qa {
    margin-bottom: 50px;
    position: relative;
    width: 100%;
    display: block;
    height: 20px;
    z-index: 1;
    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 10px;
      background-color: #eee;
      left: 0;
      top: 0;
      z-index: 0;
    }
    &-single {
      z-index: 1;
      position: absolute;
      font-size: 12px;
      i {
        // border: 1px solid $white;
        color: #555;
        border-radius: 50%;
        font-size: 20px;
        display: block;
        &::before {
          background-color: $white;
        }
      }
    }
  }
  .moments {
    margin-bottom: 50px;
    position: relative;
    width: 100%;
    display: block;
    height: 15px;
    z-index: 1;
    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 10px;
      background-color: #eee;
      left: 0;
      top: 0;
      z-index: 0;
    }
    &-single {
      z-index: 1;
      position: absolute;
      font-size: 12px;
      .dot {
        height: 15px;
        width: 15px;
        border-radius: 50%;
        background-color: $black;
        display: inline-block;
        &.Compliance {
          background-color: #d4d4d4;
        }
        &.Legal-Complaint {
          background-color: #191919;
        }
        &.Deal-Enquiry {
          background-color: #04cc00;
        }
        &.Excalation {
          background-color: #cc0000;
        }
      }
      .text {
        display: block;
      }
      i {
        color: #555;
        border-radius: 50%;
        font-size: 20px;
        display: block;
        &::before {
          background-color: $white;
        }
      }
    }
  }
  .waveDemo {
    margin-bottom: 10px;
    // .player {
    //     height: 100%;
    //     .waveGraph {
    //         height: 100%;
    //         wave {
    //             height: 100%;
    //         }
    //     }
    // }
    .waveBtn {
      .btn {
        padding: 0 5px;
      }
      .timeVal {
        font-size: 14px;
      }
    }
  }
  .transcription {
    p {
      color: #999;
      b {
        color: $black;
      }
    }
  }
}

.waveDemo {
  margin-bottom: 10px;
  // .player {
  //     height: 100%;
  //     .waveGraph {
  //         height: 100%;
  //         wave {
  //             height: 100%;
  //         }
  //     }
  // }
  .waveBtn {
    .btn {
      padding: 0 5px;
    }
    .timeVal {
      font-size: 14px;
    }
  }
}
.transcription {
  p {
    color: #999;
    b {
      color: $black;
    }
  }
}

.filter-dropdown {
  width: 550px;
  .legends {
    li {
      border-bottom: 1px solid #eee;
      font-size: 13px;
      margin: 3px 0 0;
      padding-bottom: 6px;
    }
    &-bg {
      width: 50px;
      display: inline-block;
      height: 5px;
      background-color: $black;
      position: relative;
      margin-right: 3px;
      top: -2px;
      &.agent {
        background-color: $agent;
      }
      &.customer {
        background-color: $customer;
      }
      &.speaker-0 {
        background-color: $speaker-0;
      }
      &.speaker-1 {
        background-color: $speaker-1;
      }
      &.speaker-2 {
        background-color: $speaker-2;
      }
      &.speaker-3 {
        background-color: $speaker-3;
      }
      &.speaker-4 {
        background-color: $speaker-4;
      }
      &.cross-talk {
        background-color: $cross-talk;
      }
      &.redacted {
        background-color: $redacted;
      }
      &.silence-both {
        background-color: $silence-both;
      }
    }
  }
}

// new player react start
.WaveFormContainer {
  width: 100%;
  background: transparent;
  float: left;
}
.Wave {
  width: 100%;
  float: left;
}
.Wave wave {
  overflow-x: hidden !important;
}
.playerBtnGroup {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  .PlayerButton {
    color: rgba($white, 0.87);
    //background: rgba($primary, 0.7);
    background: var(--color-main);
    opacity: 0.7;
    border-radius: 50px;
    font-size: 22px;
    height: 33px;
    min-width: 33px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 0 2px;
    transition: all 200ms linear;
    .k-button-text {
      line-height: 0;
    }
  }
}
// new player react end

// **VA-239**: On Hover over sentiment the bar is expanding which outside the page layout
// .top-player .track-single > .trackCursor:hover {
//   transform: scale(1.15);
//   z-index: 99;
//   transition: all 0.5s;
// }

.top-player .track-single > .trackCursor {
  cursor: pointer;
}
